import React from "react";

export const ThumbnailImage = React.memo(
  ({
    src,
    alt,
    variant = "default",
    className = "",
  }: {
    src: string;
    alt: string;
    variant?: "default" | "thin";
    className?: string;
  }) => {
    const containerClasses =
      variant === "thin" ? "w-24 h-24" : "w-full h-48 md:w-48 md:h-48";

    return (
      <div
        className={`relative ${containerClasses} overflow-hidden bg-gray-200 ${className}`}
      >
        <img
          src={src}
          alt={alt}
          className="absolute inset-0 w-full h-full object-cover"
          loading="lazy"
        />
      </div>
    );
  },
);

ThumbnailImage.displayName = "ThumbnailImage";

export default ThumbnailImage;

import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { NavLink } from "react-router-dom";

export function Footer(): JSX.Element {
  const footerNavItems = [
    { path: "/?fl=1", label: "Home", end: true },
    { path: "/faq", label: "FAQ" },
    { path: "/pricing", label: "Pricing" },
    {
      path: "https://www.syllabyte.ai",
      external: true,
      label: "For Publishers",
    },
    { path: "/contact", label: "Contact" },
    { path: "/privacy", label: "Privacy" },
    { path: "/terms", label: "Terms of Service" },
  ];

  return (
    <footer className="bg-toolkitBlack pt-12">
      <div className="mx-auto max-w-md py-12 px-4 overflow-hidden sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
        <nav
          className="-mx-5 -my-2 flex flex-wrap justify-center"
          aria-label="Footer"
        >
          {footerNavItems.map(item => (
            <div key={item.label} className="px-5 py-2">
              {item.path &&
                (item.external ? (
                  <a
                    href={item.path}
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                    className="text-base text-white hover:text-gray-300 hover:border-b hover:border-gray-400 inline-flex items-center"
                  >
                    {item.label}
                    <ArrowTopRightOnSquareIcon className="ml-1 h-4 w-4" />
                  </a>
                ) : (
                  <NavLink
                    to={item.path}
                    className={({ isActive }) => {
                      return clsx(
                        "text-base text-white hover:text-gray-300 hover:border-b hover:border-gray-400",
                        isActive ? "border-b border-gray-400" : "",
                      );
                    }}
                  >
                    {item.label}
                  </NavLink>
                ))}
            </div>
          ))}
        </nav>
        <div className="mt-12 xs:pt-0 md:pt-12 md:flex xs:text-center md:text-left xs:items-center md:justify-between">
          <div className="text-center">
            <p className="text-sm text-white">Powered by: </p>
            <a
              href="https://www.syllabyte.ai"
              target="_blank"
              rel="noreferrer noopener nofollow"
              className="inline-flex items-center justify-center"
            >
              <img
                loading="lazy"
                className="h-12 mx-auto"
                src="https://assets.syllabyte.ai/images/syllabyte-horizontal-light.svg"
                alt="Syllabyte.ai"
              />
              <ArrowTopRightOnSquareIcon className="ml-1 h-4 w-4 text-white" />
            </a>
            <p className="mt-8 text-gray-400 text-base xs:mb-4 md:mb-0 md:mt-0 md:order-1">
              ©2025 SyllabyteAI, Inc. All rights reserved.
            </p>
          </div>
          <div className="flex xs:pt-4 md:pt-0 space-x-6 md:order-2">
            <a
              className="text-gray-400 hover:text-gray-300 border-b border-gray-400 hover:border-gray-300 inline-flex items-center"
              target="_blank"
              rel="noreferrer noopener nofollow"
              title="Status Page"
              href="https://teachologyai.statuspage.io/"
            >
              Status
              <ArrowTopRightOnSquareIcon className="ml-1 h-4 w-4" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}
